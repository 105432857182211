<template>
    <LiefengContent :isBack="true" @backClick="$router.push(`/shopmanagementindex?isLineHome=${$route.query.isLineHome}`)">
        <!-- 属地化导入并同步 -->
        <template v-slot:title>
            导入并同步
            <!-- <Button type="error" style="margin: 0 4px" @click="$router.push(`/shopmanagementindex?isLineHome=${$route.query.isLineHome}`)" >返回</Button> -->
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input style="width: 140px;margin-right:10px" v-model="search.name" placeholder="请输入单位名称搜索"></Input>
                </FormItem>
                <FormItem>
                    <Select transfer clearable style="width: 140px;margin-right:10px" v-model="search.businessStatus" placeholder="请选择营业状态">
                        <Option value="">全部</Option>
                        <Option value="1">营业中</Option>
                        <Option value="2">已停业</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select transfer clearable style="width: 140px;margin-right:10px" v-model="search.syncStatus" placeholder="请选择同步状态">
                        <Option :value="allStatus">全部</Option>
                        <Option value="1">未同步</Option>
                        <Option value="2">已同步</Option>
                    </Select>
                </FormItem>
                <Button style="margin: 0 4px" @click="searchBtn" type="primary" icon="ios-search">查询</Button>
                <Button type="success" style="margin: 0 4px" @click="reset" icon="ios-refresh">重置</Button>
                <Button type="primary" style="margin: 0 4px" @click="importExcel" icon="ios-add-circle-outline">导入</Button>
                <Button type="primary" style="margin: 0 4px" @click="confirmImport(tableSelectDataId)">批量同步 {{ num }}</Button>
                <Button type="error" style="margin: 0 4px" @click="delImport(tableSelectDataId)">批量删除 {{ num }}</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
                v-if="showTable"
            ></LiefengTable>
            <!-- 导入 -->
            <LiefengModal title="导入" width="40%" height="230px" :value="excelStatus" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea>
                    <!-- <Form :model="searchForm" :label-width="100">
            <FormItem>
               <span slot="label" class="validate">请选择社区:</span>
               <Tooltip placement="right-start"  style="width:100%">
                <div slot="content" style="width: 200px;white-space: normal">
                  由于社区太多，请输入关键字进行刷选
                </div>
                <Select
                  v-model="nodeId"
                  filterable
                  @on-change="getTree"
                  placeholder="点击列表/输入搜索"
                  :remote-method="loadingCommunityFn"
                  :loading="loadingCommunity"
                >
                <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select>
            </Tooltip>
            </FormItem>
          </Form> -->

                    <LiefengUploadExcel
                        style="margin:20px 0"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/localization.xlsx', downName: '属地化导入模板' }"
                        :headers="headers"
                        :action="exportAction"
                        :uploadData="uploadData"
                        :isShow="showExcel"
                        @success="excelsuccess"
                    ></LiefengUploadExcel>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengUploadExcel from "./LiefengUploadExcel.vue"
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"

export default {
    data() {
        return {
            allStatus: 0,
            num: 0,

            tableSelectArr: [], //table选中的
            tableSelectDataId: "", //table选中的

            showExcel: 0,
            exportAction: "",
            headers: {
                // 'Content-Type':'application/x-www-form-urlencoded',
                openId: window.sessionStorage.getItem("openId"),
                Authorization: window.sessionStorage.getItem("accessToken"),
            },
            nodeList: [],
            //异步加载社区
            loadingCommunity: false,
            nodeId: "",
            // 导入框
            excelStatus: false,
            // 上传地址
            excelAction: "",
            //上传附带而外参数
            uploadData: {
                // file:,
                communityCode: "",
                orgCode: "",
                impStaff: "",
            },
            //设置上传的请求头
            uploadHeaders: {},
            //默认已上传的文件列表
            fileList: [],
            //excel导入错误信息
            errorContent: null,
            //导入文字显示标识
            textStatus: false,
            //excel文件数据
            excelFile: null,

            //导入地址
            // action:window.vue.getProxy()["/syaa"].target + "/api/syuser/pc/company/importImpUser",

            //table表格参数
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "单位名称",
                    key: "name",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "单位性质",
                    key: "nature",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            this.natureList.map(item => {
                                if (item.dictKey == params.row.nature) {
                                    return item.dictValue
                                }
                            })
                        )
                    },
                },

                {
                    title: "营业状态",
                    key: "businessStatus",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.businessStatus ? (params.row.businessStatus == 1 ? "营业中" : params.row.businessStatus == 2 ? "已停业" : "") : "")
                    },
                },
                {
                    title: "联系地址",
                    key: "addr",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "确认状态",
                    key: "syncStatus",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.syncStatus == 1 ? "未同步" : params.row.syncStatus == 2 ? "已同步" : params.row.syncStatus == 3 ? "同步失败" : "")
                    },
                },
                {
                    title: "操作",
                    width: 300,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                        type: "primary",
                                    },
                                    style: {
                                        marginRight: "10px",
                                        display: params.row.syncStatus == 2 ? "none" : "span",
                                    },
                                    on: {
                                        click: () => {
                                            this.confirmImport(params.row.companyId)
                                        },
                                    },
                                },
                                "同步"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                        type: "primary",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.delImport(params.row.companyId)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,
            currentPage: 0,
            // 搜索内容集合
            search: {
                name: "",
                // nature:'',
                // industryCodeList:[],
                businessStatus: "", //营业状态
                syncStatus: "1",
                // startDate:'',
                // endDate:''
            },
            // dateTime:[],
            // 行业类型
            industryList: [],
            natureList: [],
            // 显示table表的显示隐藏
            showTable: true,
        }
    },

    async created() {
        console.log(parent.vue.loginInfo.userinfo)
        await this.getIndustry("INDUSTRY_TYPE", "industryList")
        await this.getIndustry("UNIT_NATURE", "natureList")
        await this.getList()
        //  this.getSelect()
    },
    methods: {
        // 确认导入
        confirmImport(id) {
            if (id) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "是否同步当前店铺",
                    onOk: () => {
                        this.$post("/gateway/api/sycompany/pc/company/syncImpCompany", {
                            companyIds: id,
                            syncStaff: parent.vue.loginInfo.userinfo.realName,
                            communityCode: this.$core.getUrlParam("communityCode"),
                        }).then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "操作成功",
                                })
                                this.getList()
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        this.tableSelectDataId = ""
                        this.num = 0
                    },
                })
            } else {
                this.$Message.warning({
                    background: true,
                    content: "请选择操作对象",
                })
            }
        },
        //删除导入
        delImport(id) {
            if (id) {
                this.$Modal.confirm({
                    title: "切换确认",
                    content: "是否删除当前待同步店铺",
                    onOk: () => {
                        this.$post("/gateway/api/sycompany/pc/company/deleteByIds", {
                            companyIds: id,
                        }).then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "操作成功",
                                })
                                this.getList()
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        this.tableSelectDataId = ""
                        this.num = 0
                    },
                })
            } else {
                this.$Message.warning({
                    background: true,
                    content: "请选择操作对象",
                })
            }
        },
        // 选中
        tableSelect(data) {
            //表格选中
            this.num = data.length
            console.log(data)
            let tableSelectDataId = []
            if (data.length != 0) {
                data.forEach(res => {
                    tableSelectDataId.push(res.companyId)
                })
            }
            this.tableSelectDataId = tableSelectDataId.join()
        },

        //   getSelect(){
        //    this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
        //       staffId: parent.vue.loginInfo.userinfo.id,
        //       orgCode:parent.vue.loginInfo.userinfo.orgCode
        //     })
        //       .then((res) => {
        //         if (res.code === "200"&&res.dataList&&res.dataList.length>0) {
        //           // this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
        //           this.nodeList = res.dataList.map((item) => {
        //             return {
        //               value: item.code,
        //               label: item.fullName,
        //               projectCode: item.projectCode
        //             };
        //           });
        //           this.allNodeList = JSON.parse(JSON.stringify(this.nodeList));
        //           if(this.nodeList.length > 50) {
        //             this.nodeList.length = 50;
        //           }
        //         }
        //       })
        //       .catch((err) => {
        //         console.log(err);
        //       });
        // },
        //异步加载社区
        // loadingCommunityFn(query) {
        //    if(query !== '' && !Number(query)) {
        //     this.loadingCommunity = true;
        //    this.nodeList = this.allNodeList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
        //    if(this.nodeList.length > 50) this.nodeList.length = 50;
        //     this.loadingCommunity = false;
        //   } else if(Number(query)) {
        //     return;
        //   }
        //   else {
        //     this.nodeList = [];
        //   }
        // },
        // getTree(val){
        //     this.nodeId = val
        //     this.uploadData.communityCode = this.nodeId
        // },
        //修改excel导入弹窗状态
        excelModalData(status) {
            this.excelStatus = status
            // this.showExcel = false
        },

        // 导入
        importExcel() {
            // this.nodeId = ''
            this.uploadData.communityCode = this.$route.query.communityCode
            this.uploadData.orgCode = parent.vue.loginInfo.userinfo.orgCode
            this.uploadData.impStaff = parent.vue.loginInfo.userinfo.nickName
            this.excelStatus = true
            this.showExcel = this.showExcel + 1
            this.exportAction = window.vue.getProxy()["/gateway"].target + "/upload/sycompany/pc/company/importImpCompany"
            // this.exportAction =  "syaa/api/syuser/pc/company/importImpUser";
            console.log("true")
        },
        // 导入成功回调事件
        excelsuccess() {
            console.log("成功1111111111111111111111111111111111111")
            this.excelStatus = false
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },

        // 改变分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 查询按钮
        searchBtn() {
            this.page = 1
            this.getList()
        },
        // 重置按钮
        reset() {
            this.page = 1
            this.search = {
                name: "",
                businessStatus: "",
            }
            this.getList()
        },

        // 接口部分
        // 获取分页数据
        async getList() {
            this.loading = true
            await this.$get("/gateway/api/sycompany/pc/company/getImpCompanyPage", {
                page: this.page,
                pageSize: this.pageSize,
                ...this.search,
                communityCode: this.$route.query.communityCode,
                businessStatus: this.search.businessStatus,
                name: this.search.name,
                syncStatus: this.search.syncStatus ? this.search.syncStatus : "",
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage

                    console.log("this.tableData", this.tableData)
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },

        // 获取行业分类接口
        async getIndustry(type, model) {
            await this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType: type,
            }).then(res => {
                if (res.code == 200) {
                    this[model] = res.dataList
                }
            })
        },

        //  提供的公共方法
        // 校验身份证
        testid(id) {
            // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
            var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/
            //号码规则校验
            if (!format.test(id)) {
                return false
            }
            //区位码校验
            //出生年月日校验  前正则限制起始年份为1900;
            var year = id.substr(6, 4), //身份证年
                month = id.substr(10, 2), //身份证月
                date = id.substr(12, 2), //身份证日
                time = Date.parse(month + "-" + date + "-" + year), //身份证日期时间戳date
                now_time = Date.parse(new Date()), //当前时间戳
                dates = new Date(year, month, 0).getDate() //身份证当月天数
            if (time > now_time || date > dates) {
                return false
            }
            //校验码判断
            var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2) //系数
            var b = new Array("1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2") //校验码对照表
            var id_array = id.split("")
            var sum = 0
            for (var k = 0; k < 17; k++) {
                sum += parseInt(id_array[k]) * parseInt(c[k])
            }
            if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
                return false
            }
            return true
        },
    },
    components: {
        LiefengUploadExcel,
        LiefengContent,
        LiefengTable,
        LiefengModal,
    },
}
</script>

<style scoped lang="less">
.isForm {
    display: flex;
    flex-wrap: wrap;
    .isWidth {
        width: 40%;
        margin-right: 40px;
    }
    .isNoWidth {
        width: 100%;
    }
}
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.search {
    /deep/.ivu-form-item-content {
        margin-left: 0 !important;
    }
}
</style>
